<template>
  <div class="content-list">
    <div class="content-cont">
      <p class="content-title">用户注册</p>
      <div class="forms-form" v-loading="loading" element-loading-text="保存中" >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          size="medium"
          label-width="340px"
        >
          <div> 
            <el-row>
              <el-col :span="24">
                <el-form-item label="亲！您的经营地址所在区县、乡/镇/街、村/社区：" prop="address">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.address }}</p>
                  <el-input class="my-input-btn my-input-btn1" v-else :disabled="true" placeholder="请选择" suffix-icon="el-icon-arrow-down" @click.native="chooseType('getpickarea')" v-model="ruleForm.address"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="您有生产基地吗？：" prop="pro1">
                  <el-radio-group v-model="ruleForm.pro1" @change="change1" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.pro1"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="ruleForm.pro1 == '00'">
              <el-col :span="24">
                <el-form-item label="是否是散户？：" prop="retail">
                  <el-radio-group v-model="ruleForm.retail" @change="change2" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.retail"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="rules.productionBodyType[0].required">
              <el-col :span="24">
                <el-form-item label="您是属于以下哪类？：" prop="productionBodyType">
                  <el-radio-group v-model="ruleForm.productionBodyType" @change="change3" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.productionBodyType"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="rules.pro2[0].required">
              <el-col :span="24">
                <el-form-item label="您的生产基地是：" prop="pro2">
                  <el-checkbox-group v-model="ruleForm.pro2" :disabled="editType == 'look'">
                    <el-checkbox v-for="(item, index) in selectObj.pro2" :label="item.value" :key="index">{{item.label}}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="rules.businessBodyType[0].required">
              <el-col :span="24">
                <el-form-item label="您（还）主要从事哪种经营活动：" prop="businessBodyType">
                  <el-radio-group v-model="ruleForm.businessBodyType" @change="change4" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.businessBodyType"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                    <el-radio label="15" v-if="ruleForm.pro1 == '1'">以上都不是</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="rules.pro3[0].required">
              <el-col :span="24">
                <el-form-item label="您是农贸批发市场或零售市场开办者管理机构吗？：" prop="pro3">
                  <el-radio-group v-model="ruleForm.pro3"  @change="change5" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.pro3"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="ruleForm.pro3 == '1'">
              <el-col :span="24">
                <el-form-item label="管理机构主体类别是？：" prop="monitoringUnitType">
                  <el-radio-group v-model="ruleForm.monitoringUnitType" :disabled="editType == 'look'">
                    <el-radio
                      v-for="(item, index) in selectObj.monitoringUnitType"
                      :key="index"
                      :label="item.value"> {{ item.label }}
                    </el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="rules.bodyName[0].required">
              <el-col :span="24">
                <el-form-item label="主体名称：" prop="bodyName">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.bodyName }}</p>
                  <el-input clearable v-else v-model="ruleForm.bodyName" :placeholder="ruleForm.retail == '01' ? '请输入主体名称' : '请输入企业名称'" maxlength=64 show-word-limit type="textarea" :rows="3"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="ruleForm.retail == '01' ? '姓名：' : '法人：'" prop="representativeName">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.representativeName }}</p>
                  <el-input clearable v-else v-model="ruleForm.representativeName" :placeholder="ruleForm.retail == '01' ? '请输入姓名' : '请输入法人姓名'" maxlength=16 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item :label="ruleForm.retail == '01' ? '身份证号：' : '社会信用代码：'" prop="creditCode">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.creditCode }}</p>
                  <el-input clearable v-else v-model="ruleForm.creditCode" :placeholder="ruleForm.retail == '01' ? '请输入身份证号' : '请输入社会信用代码'" maxlength=18 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="社区及门牌地址：" prop="doorNumber">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.doorNumber }}</p>
                  <el-input clearable v-else v-model="ruleForm.doorNumber" @change="inputChange" placeholder="请输入社区及门牌地址" maxlength=32 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="经营地址：" prop="businessAddress">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.businessAddress }}</p>
                  <el-input clearable v-else v-model="ruleForm.businessAddress" placeholder="自动生成" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="登录账号：" prop="userName">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.userName }}</p>
                  <el-input clearable v-else v-model="ruleForm.userName" placeholder="请输入登录账号" auto-complete="off" autocomplete="off" maxlength=18 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="登录密码：" prop="password">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.password }}</p>
                  <el-input clearable v-else v-model="ruleForm.password" placeholder="请输入登录密码" auto-complete="off" autocomplete="off" maxlength=18 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="第二次确认登录密码：" prop="password2">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.password2 }}</p>
                  <el-input clearable v-else v-model="ruleForm.password2" placeholder="请确认登录密码" maxlength=18 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="手机号：" prop="mobilePhoneNumber">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.mobilePhoneNumber }}</p>
                  <el-input clearable v-else v-model="ruleForm.mobilePhoneNumber" placeholder="请输入手机号" maxlength=14 show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="手机验证码：" prop="verificationCode">
                  <p v-if="editType == 'look'" class="view-cont">{{ ruleForm.verificationCode }}</p>
                  <el-input v-else :class="sendFlag ? 'my-input-btn' :  'my-input-btn-gray'" v-model="ruleForm.verificationCode" placeholder="请输入手机验证码">
                    <el-button slot="append" v-if="sendFlag" @click="sendMsg('mobilePhoneNumber')" type="primary">获取验证码</el-button>
                    <el-button slot="append" v-else class="btn-gray"  type="primary">{{countdown}}秒后重新发送</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
              <p class="tips-gray">验证码10分钟有效</p>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="forms-btns" slot="footer">
        <el-button type="primary" v-if="editType != 'look'" :loading="loading" size="small" @click="submitForm()">提交注册</el-button>
        <el-button @click="closeModel" :disabled="loading" size="small"> {{ editType == 'look' ? '关闭' : '取消' }}</el-button>
      </div>
    </div>
    <pick-area ref="getpickarea" @getpickarea="getpickarea" needRequest></pick-area>
  </div>
</template>
<script>
import { request, utils, CONSTPARAMS, baseValidate } from '@/utils/baseUtils'
import pickArea from '@/views/common/pickArea.vue'
let _t = null
export default {
  name: "register",
  components: {
    pickArea
  },
  props:{
  },
  data() {
    return {   
      rules: {
        address: [{ required: true,  trigger: "change", message: '请选择所属区域'}],
        retail: [{ required: true,  trigger: "change", message: '请选择是否散户'}],
        monitoringUnitType: [{ required: false,  trigger: "change", message: '请选择管理机构主体类别'}],
        bazaarType: [{ required: false,  trigger: "change", message: '请选择市场类型'}],
        pro1: [{ required: true,  trigger: "change", message: '请选择'}],
        pro2: [{ required: true,  trigger: "change", message: '请选择生产基地'}],
        pro3: [{ required: false,  trigger: "change", message: '请选择农贸批发市场或零售市场管理机构'}],
        representativeName: [{ required: true,  trigger: "change", message: '请输入'}],
        bodyName: [{ required: true,  trigger: "change", message: '请输入主体名称'}],
        doorNumber: [{ required: true,  trigger: "change", message: '请输入社区及门牌地址'}],
        businessBodyType: [{ required: true,  trigger: "change", message: '请选择经营活动'}],
        productionBodyType: [{ required: true,  trigger: "change", message: '请选择生产主体类型'}],
        verificationCode: [{ required: true,  trigger: "change", message: '请输入验证码'}],
        password: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入密码"))
            }else{
              if(val.length < 6){
                callback(new Error("密码长度要大于等于6位"))
              }else{
                callback()
              }
            }
          }
        }],
        password2: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请确认密码"))
            }else{
              if(val != _t.ruleForm.password){
                callback(new Error("两次输入的密码不一致"))
              }else{
                callback()
              }
            }
          }
        }],
        mobilePhoneNumber: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入手机号"))
            }else if(val && !baseValidate.tel(val)){
              callback(new Error("请输入正确的手机号"))
            }else{
              callback()
            }
          }
        }],
        creditCode: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(rule.required && !val){
              callback(new Error("请输入"))
            }else{
              if(!rule.required && !val) callback()

              let crecode = _t.ruleForm.creditCode || '',
                len = crecode?.length
              if(_t.ruleForm.pro1 == '00'){
                if(!(len == 15 || len == 18)){
                  callback(new Error("请输入正确的统一社会信用代码或身份证"))
                }
              }else{
                if(_t.rules.bodyName[0].required){
                  if(!(len == 15 || len == 18)){
                    callback(new Error("请输入正确的统一社会信用代码"))
                  }
                }else{
                  if(len != 18){
                    callback(new Error("请输入正确的身份证号码"))
                  }
                }
              }
              callback()
            }
          }
        }],
        userName: [{ required: true, trigger: "change", 
          validator: (rule, val, callback) => {
            if(!val){
              callback(new Error("请输入登录账号"))
            }else{
              if(val.length < 6){
                callback(new Error("登录账号长度要大于等于6位"))
              }else{
                callback()
              }
            }
          }
        }]

      },
      closeFlag: false,
      loading: false,
      title: '',
      editType: 'add',
      ruleForm: {
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        county: '',
        countyCode: '',
        town: '',
        townCode: '',
        village: '',
        villageCode: '',
        pro1: '',
        productionBodyType: '',
        businessBodyType: '',
        pro2: [],
        pro3: '',
        bodyName: '',
        businessAddress: '',
        doorNumber: '',
        address: '',
        representativeName: '',
        creditCode: '',
        mobilePhoneNumber: '',
        password: '',
        password2: '',
        registerChannel: '02',
        registerLongitude: '',
        registerLatitude: '',
        plant: '0',
        farming: '0',
        aquatic: '0',
        verificationCode: '',
        bodyType: '',
        userName: '',
        monitoringUnitType: '',
        levelType: '02',
        bazaarType: '',
        retail: '02', //01散户
      },
      initForm:{},
      selectObj: {
        pro2: CONSTPARAMS.businessRange,
        list: CONSTPARAMS.bodyType,
        productionBodyType: CONSTPARAMS.productionBodyType,
        businessBodyType: CONSTPARAMS.businessBodyType,
        retail: CONSTPARAMS.retail,
        monitoringUnitType: [
          // { label: '农业农村监管单位', value: '01' },
          // { label: '市场监管单位', value: '02' },
          // { label: '商务监管单位', value: '03' },
          { label: '市场经营管理企业', value: '21' },
          // { label: '市场', value: '22' },
          { label: '采样单位', value: '31' },
          { label: '检测单位', value: '32' },
          { label: '其他监管单位', value: '04' }
        ],
        list4: [
          { label: '国级', value: '01' },
          { label: '省级', value: '02' },
          { label: '市级', value: '03' },
          { label: '县级', value: '04' },
          { label: '乡镇级', value: '05' },
          { label: '村级', value: '06' }
        ],
        list5: [
          { label: '综合', value: '01' },
          { label: '批发', value: '02' },
          { label: '零售', value: '03' }
        ],
        pro1: [
          { label: '有', value: 1 },
          { label: '无', value: '00' }
        ],
        pro3: [
          { label: '是', value: 1 },
          { label: '否', value: '00' }
        ]
      },
      addresses: [],
      countdown: CONSTPARAMS.countdown,
      sendFlag: true,
    };
  },
  created() {
	},
  mounted() {
    _t = this
  },
  methods: {
    sendMsg(prop){
      utils.sendMsg(this, prop, 'register')
    },
    change1(val){
      if(val == 1){ //有
        this.rules.productionBodyType[0].required = true
        this.rules.pro2[0].required = true
        this.ruleForm.retail = '02'
      }else{
        this.rules.productionBodyType[0].required = false
        this.rules.pro2[0].required = false
        this.ruleForm.retail = ''
      }
      this.rules.businessBodyType[0].required = true
      this.rules.bazaarType[0].required = false
      this.rules.monitoringUnitType[0].required = false

      this.ruleForm.businessBodyType = ''
      this.ruleForm.productionBodyType = ''
      this.ruleForm.monitoringUnitType = ''
      this.ruleForm.bazaarType = ''
      this.ruleForm.pro3 = ''
      this.ruleForm.pro2 = []
    },
    change2(val){
      this.rules.bodyName[0].required = val == '01' ? false : true
    },
    change3(val){
      if(val == '05'){
        this.rules.bodyName[0].required = false
        this.ruleForm.retail = '01'
      }else{
        this.rules.bodyName[0].required = true
        this.ruleForm.retail = '02'
      }
    },
    change4(val){
      this.rules.pro3[0].required = (this.ruleForm.pro1 == '00' && val == '15') ? true : false
      this.rules.monitoringUnitType[0].required = false
      this.rules.bazaarType[0].required = false
      this.ruleForm.pro3 = ''
      this.ruleForm.monitoringUnitType = ''
      this.ruleForm.bazaarType = ''
    },
    change5(val){
      if(val == '00'){
        this.rules.monitoringUnitType[0].required = false
        this.$alert('您的业务，不在本平台管理范围内，谢谢您的关注！', '提示', {
          confirmButtonText: '确定',
          callback: () => {
            this.$router.push({
              path: '/login'
            })
          }
        })
      }else{
        this.rules.monitoringUnitType[0].required = true
      }
      this.ruleForm.monitoringUnitType = ''
      this.ruleForm.bazaarType = ''
    },
    chooseType(name){
      this.$refs[name].initData()
    },
    inputChange(val){
      this.ruleForm.businessAddress = (this.ruleForm.address || '') + (val || '')
    },
    getpickarea(data){
      let address = ''
      data.forEach(cur => {
        if(cur.areaCode){
          address += cur.areaName
        }
      })
      this.ruleForm.province = data[0] && data[0].areaName || ''
      this.ruleForm.provinceCode = data[0] && data[0].areaCode || ''
      this.ruleForm.city = data[1] && data[1].areaName || ''
      this.ruleForm.cityCode = data[1] && data[1].areaCode || ''
      this.ruleForm.county = data[2] && data[2].areaName || ''
      this.ruleForm.countyCode = data[2] && data[2].areaCode || ''
      this.ruleForm.town = data[3] && data[3].areaName || ''
      this.ruleForm.townCode = data[3] && data[3].areaCode || ''
      this.ruleForm.village = data[4] && data[4].areaName || ''
      this.ruleForm.villageCode = data[4] && data[4].areaCode || ''
      this.ruleForm.address = address || ''
      this.ruleForm.businessAddress = address + (this.ruleForm.doorNumber || '')
    },
    setAddress(data = {}){
      this.ruleForm.province = data.province || ''
      this.ruleForm.provinceCode = data.provinceCode || ''
      this.ruleForm.city = data.city || ''
      this.ruleForm.cityCode = data.cityCode || ''
      this.ruleForm.county = data.county || ''
      this.ruleForm.countyCode = data.countyCode || ''
      this.ruleForm.town = data.town || ''
      this.ruleForm.townCode = data.townCode || ''
      this.ruleForm.village = data.village || ''
      this.ruleForm.villageCode = data.villageCode || ''
      this.ruleForm.address = utils.getAddressByObj(data, true)
      this.$refs.getpickarea.resetAddress(utils.getAddressByObj(data))
    },
    async initData(title, editType, ruleForm, transobj = {}){
      if(editType == 'add'){
        let id = await utils.getNewId(),
          obj = {
          ...this.initForm,
          ...transobj,
          baseId: id,
          baseProductList: []
        }
        this.addresses = []
        this.ruleForm = obj
      }else{
        this.ruleForm = { 
          ...ruleForm,
          baseProductName: this.getRealLables(ruleForm.baseProductList),
        }
      }
      this.title = title
      this.editType = editType
      this.closeFlag = true
      this.resetForm()
      editType == 'add' && this.initAdd()
      editType == 'edit' && this.initEdit()
      editType == 'look' && this.initLook()
    },
    initAdd(){
    },
    initEdit(){
      this.addresses = utils.getAddressByObj(this.ruleForm)
    },
    initLook(){
      this.initEdit()
    },
    async submitForm() {
      let _this = this,
        formName = 'ruleForm'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.submitData()
        } else {
          _this.message('请先完善信息！', "warning")
          return false
        }
      });
    },
    async submitData(){
      let _this = this,
        contObj = this.ruleForm

      _this.loading = true
      let flag = await utils.codeCheck('register', contObj.mobilePhoneNumber, contObj.verificationCode) || false
      if(!flag) {
        _this.loading = false 
        return
      }
      //11生产主体、12经营主体、13生产和经营主体
      if(contObj.pro1 == '00'){
        contObj.bodyType = '12'
      }else if(contObj.businessBodyType == '15'){
        contObj.bodyType = '11'
      }else{
        contObj.bodyType = '13'
      }

      if(contObj.retail == '01'){
        contObj.bodyName = contObj.representativeName
      }

      //生产基地
      contObj.plant = '0'
      contObj.farming = '0'
      contObj.aquatic = '0'
      const pro2 = contObj.pro2 || []
      pro2.length && pro2.forEach(cur => {
        contObj[cur] = '1'
      })
      
      request.saveuser({ ...contObj, pro2: '' }).then((res) => {
        if(res.code == 200){
          _this.message('注册成功，您可以登录了！', "success")
          _this.$router.go(-1)
        }else{
          _this.message(res.msg || '', "error")
        }
        _this.loading = false
      }).catch(e => {
        console.log(e)
        _this.loading = false
      })

    },
    closeModel() {
      this.$router.go(-1)
    },
    resetForm(formName = 'ruleForm') {
      this.$refs[formName]?.resetFields();
    }
  },
  watch: {
    // 'ruleForm.smpOrgid': {
    //   handler(val){
    //     this.ruleForm.smpOrgid = val
    //     this.queryData()
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
  .content-list{
    background-color: #f2f3f4;
    padding: 20px;
    height: 100vh;
    box-sizing: border-box;
    overflow: auto;
  }
  .content-title{
    padding: 10px 0 40px;
    text-align: center;
    font-size: 20px;
  }
  .content-cont{
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 #f2f3f4;
  }
  .el-row{
    padding: 5px;
  }
  .tips-gray{
    margin-left: 240px;
  }
  .forms-btns {
    text-align: center;
    padding: 30px 20px 10px;
  }
</style>


